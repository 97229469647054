import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IOption } from "../../../../TargetedTools/components/MultipleSelect";
import { useSelect } from "../../../../../hooks/useSelect";
import { getSystemPromoCode } from "../../../../../redux-store/actions/getSystemPromoCodesActions";
import {
  CustomDropDownSelect,
  MultipleSelect,
} from "../../../../TargetedTools/components";
import { ISelectItemData } from "../../../../TargetedTools/components/CustomDropDownSelect";
import styles from "./styles.module.sass";
import { getCountryStores } from "../../../../../redux-store/actions/getCountryStoresActions";
export const PromocodeFilters: React.FC = () => {
  const dispatch = useDispatch();
  const {
    countries,
    stores,
    filters: { status, country_codes, brand_ids, order_by, search },
  } = useSelect((state) => ({
    ...state.countriesReducer,
    ...state.CountryStoresReducer,
    ...state.SystemPromoCodesReducer.current,
  }));
  const renderSelectItem = (item: ISelectItemData) => (
    <div className={styles.dropdownItem}>
      <span className={styles.dropdownItemLabel}>{item?.label || "All"}</span>
    </div>
  );
  const renderSelectToggle = (item: ISelectItemData) => (
    <div className={styles.dropDownSelect}>
      <div className={styles.dropdownItemSelected}>
        <span className={styles.dropdownLabel}>Promo code status:</span>
        <span className={styles.dropdownValue}>{item?.label || "All"}</span>
      </div>
      <img
        className={styles.dropdownIcon}
        src={require("../../../../../assets/feather _ chevron-down.svg")}
      />
    </div>
  );
  {
    /* Filter promo codes by activity */
  }
  {
    /* activity options */
  }
  const statusOptions: ISelectItemData[] = React.useMemo(() => {
    return [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Active",
        value: "active",
      },
      {
        label: "Inactive",
        value: "inactive",
      },
    ];
  }, []);
  {
    /* handle select to filter promo code by activity  */
  }
  const handleStatusPromocodeSelect = (item: ISelectItemData) => {
    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: {
          country_codes,
          status: item.value,
          brand_ids,
          order_by,
          search,
        },
      })
    );
  };

  {
    /* Filter by country */
  }
  const countriesOptions = React.useMemo(() => {
    return [
      ...countries.map(
        ({ ISO_code, EnName }): IOption<string> => ({
          label: EnName,
          value: ISO_code,
        })
      ),
    ];
  }, [countries]);
  {
    /* handle select to filter by countries */
  }

  const handleCountriesSelect = (values, setValues, id) => {
    const index = values.findIndex((b: any) => b === id);
    let countryISOCode: any;
    if (index > -1) {
      const valuesCopy: string[] = [...values];
      valuesCopy.splice(index, 1);
      setValues(valuesCopy);
      countryISOCode = valuesCopy.map(
        (item) =>
          countries.find((value) => value.ISO_code === item)?.country_code
      );
      dispatch(
        getSystemPromoCode({
          page: 0,
          per_page: 15,
          filters: {
            status,
            country_codes: valuesCopy,
            brand_ids,
            order_by,
            search,
          },
        })
      );
      dispatch(
        getCountryStores(valuesCopy.length ? countryISOCode : undefined)
      );
    } else {
      setValues([...values, id] as any);
      countryISOCode = values
        .concat(id)
        .map(
          (item) =>
            countries.find((value) => value.ISO_code === item)?.country_code
        );

      dispatch(
        getSystemPromoCode({
          page: 0,
          per_page: 15,
          filters: {
            status,
            country_codes: [...values, id],
            brand_ids,
            order_by,
            search,
          },
        })
      );
      dispatch(getCountryStores({ country_code: countryISOCode }));
    }
  };

  {
    /* Filter by brands  */
  }

  const brandsOptions = React.useMemo(() => {
    return [
      ...stores.map(
        (b): IOption<string> => ({
          label: b.label,
          value: b.value,
        })
      ),
    ];
  }, [stores]);
  const handleStoriesSelect = (values, setValues, id) => {
    const index = values.findIndex((b: any) => b === id);

    if (index > -1) {
      const valuesCopy: string[] = [...values];
      valuesCopy.splice(index, 1);
      setValues(valuesCopy);
      dispatch(
        getSystemPromoCode({
          page: 0,
          per_page: 15,
          filters: {
            status,
            country_codes,
            brand_ids: valuesCopy,
            order_by,
            search,
          },
        })
      );
    } else {
      setValues([...values, id] as any);
      dispatch(
        getSystemPromoCode({
          page: 0,
          per_page: 15,
          filters: {
            status,
            country_codes,
            brand_ids: [...values, id],
            order_by,
            search,
          },
        })
      );
    }
  };
  useEffect(() => {
    dispatch(getCountryStores([...countries.map((c) => c.country_code)]));
  }, []);
  const countriesDispatching = () => {
    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: {
          status,
          brand_ids,
          country_codes: undefined,
          order_by,
          search,
        },
      })
    );
    dispatch(getCountryStores(undefined));
  };
  const brandsDispatching = () => {
    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: {
          status,
          country_codes,
          brand_ids: undefined,
          order_by,
          search,
        },
      })
    );
  };

  return (
    <div className={styles.filterWrapper}>
      <CustomDropDownSelect
        renderSelectItem={renderSelectItem}
        renderSelectToggle={renderSelectToggle}
        options={statusOptions}
        onChange={handleStatusPromocodeSelect}
      />
      <div className={styles.separator} />
      <MultipleSelect
        justify={"flex-end"}
        searchLabel={"brands"}
        dropdownLabel={"Brands:"}
        selectAll={brand_ids === undefined}
        onChange={handleStoriesSelect}
        options={brandsOptions}
        selectedLabel={"brands selected"}
        placeholder={"Search for brands"}
        dispatchingFunctions={brandsDispatching}
      />
      <div className={styles.separator} />
      <MultipleSelect
        justify={"center"}
        searchLabel={"countries"}
        dispatchingFunctions={countriesDispatching}
        selectAll={country_codes === undefined}
        selectedLabel={"countries selected"}
        dropdownLabel={"Country:"}
        placeholder={"Search for countries"}
        onChange={handleCountriesSelect}
        options={countriesOptions}
      />
    </div>
  );
};

export default PromocodeFilters;
